import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken, setToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_URL, // api 的 base_url
//   withCredentials: true,
});
import jsCookie from "js-cookie";
const cookies = jsCookie;
// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = cookies.get("SID");
    if (token) {
      config.headers["x-h5-session-id"] = token;
    }
    config.headers["x-h5-session-id"] = "246d6281c0ab4ec3b3d66a6951845197";
    if (config.method === "post") {
      config.headers["Content-Type"] = "application/json";
      // config.data = {
      //     ...config.data
      // };
    } else if (config.method === "get") {
      config.params = {
        ...config.params,
      };
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  (response) => {
    const res = response.data;
    // Message({
    //   message: res.msg || "error",
    //   type: "error",
    //   duration: 5 * 1000
    // });
    // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
    if (res.code === 204) {
      // 请自行在引入 MessageBox
      // import { Message, MessageBox } from 'element-ui'
      MessageBox.confirm(
        "你已被登出，可以取消继续留在该页面，或者重新登录",
        "确定登出",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        store.dispatch("user/resetToken").then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
      return Promise.reject(res.msg || "error");
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          // error.message = `请求地址出错: ${error.response.config.url}`;
          error.message = `请求地址出错`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
      }
    } else if (error.response == undefined) {
      error.message = "请求超时!";
    }
    Message({
      message: error.message || error.msg || "未知错误",
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
