import request from "@/utils/request";

// 获取商家列表
export function getMerchantList(data) {
    return request({
        url: '/getMerchantList/getMerchantList',
        method: 'post',
        data
    })
}
// 获取厂商列表
export function getSupplierList(data) {
    return request({
        url: '/getMerchantList/getSupplierList',
        method: 'post',
        data
    })
}
// 获取加入的厂商或商家
export function getUserJoinList(data) {
    return request({
        url: '/getMerchantList/getUserJoinList',
        method: 'get',
        params:data,
    })
}