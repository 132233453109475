import { getMerchantList,getSupplierList,getUserJoinList } from '@/api/sp'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    // 获取加入的厂商或商家
    getUserJoinList({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getUserJoinList(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取商家列表
    getMerchantList({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getMerchantList(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取厂商列表
    getSupplierList({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getSupplierList(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
